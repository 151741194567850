<template>
  <div>
    <QueryTable ref="table" :fields="fields" :api-path="`v1/teams/${team_id}/invites`">
      <template #cell(created_at)="{ item }">
        <DateCell :date="item.created_at"></DateCell>
      </template>
      <template #cell(expires_at)="{ item }">
        <div v-if="item.expired = parseDate(item.expires_at) < new Date()" class="badge badge-primary d-inline-flex" style="background-color: #c4081a; font-size: 13px;">
          {{ $t('teams.view.members.invites.state.expired') }}
        </div>
        <DateCell v-else :date="item.expires_at"></DateCell>
      </template>
      <template #cell(role)="{ item }">
        <RoleCell :role="item.role"></RoleCell>
      </template>
      <template #cell(user)="{ item }">
        <ProfileCell :user="item.user"></ProfileCell>
      </template>
      <template #cell(state)="{ item }">
        <div v-if="item.state === 1">{{ $t('teams.view.members.invites.state.accepted') }}</div>
        <div v-else-if="item.state === -1">{{ $t('teams.view.members.invites.state.declined') }}</div>
        <div v-else-if="item.expired">{{ $t('teams.view.members.invites.state.expired') }}</div>
        <div v-else>{{ $t('teams.view.members.invites.state.pending') }}</div>
      </template>
      <template #cell(actions)="{ item }">
        <button class="btn btn-warning btn-sm ml-2 mr-2" @click="removeInvite(item.id)">
          {{ $t('teams.view.members.invites.remove.button') }}
        </button>
      </template>
    </QueryTable>
  </div>
</template>

<script>
import QueryTable from "@/components/widgets/table/QueryTable";
import DateCell from "@/components/widgets/table/components/DateCell";
import ProfileCell from "@/components/widgets/table/components/ProfileCell";
import RoleCell from "@/components/widgets/table/components/RoleCell";
import { get_acsrf_token } from "@/methods";

export default {
  props: ['team_id', 'options'],
  components: { RoleCell, DateCell, ProfileCell, QueryTable },
  data() {
    return {
      fields: [
        {
          key: 'created_at',
          label: this.$t('teams.view.members.invites.columns.date'),
          sortable: true
        },
        {
          key: 'expires_at',
          label: this.$t('teams.view.members.invites.columns.expires'),
          sortable: true
        },
        // {
        //   key: 'role',
        //   label: this.$t('teams.view.members.invites.columns.role'),
        //   sortable: false
        // },
        {
          key: 'user',
          label: this.$t('teams.view.members.invites.columns.user'),
          sortable: true
        },
        {
          key: 'state',
          label: this.$t('teams.view.members.invites.columns.state'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('teams.view.members.invites.columns.actions'),
          sortable: false
        }
      ],
      selectedMember: null,
      newRole: null,
      availableRoles: [],
      loadingAvailableRoles: false,
      availableRolesError: false,
      memberModifyInProgress: false
    }
  },
  methods: {
    async removeInvite(invite_id) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('teams.view.members.invites.remove.text'),
        showCancelButton: true,
        confirmButtonText: this.$t('teams.view.members.invites.remove.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if (!result.isConfirmed) {
        return;
      }
      try {
        const acsrf_token = await get_acsrf_token();
        const payload = {
          acsrf_token: acsrf_token
        };
        const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/invites/${invite_id}`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if (response.ok) {
          await this.$refs.table.fetchData();
          this.$parent.$parent.update();
          this.$swal({
            icon: 'success',
            text: this.$t('teams.view.members.invites.remove.success.text')
          });
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          }
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    }
  }
}
</script>