var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('QueryTable',{ref:"table",attrs:{"fields":_vm.fields,"api-path":("v1/teams/" + _vm.team_id + "/invites")},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('DateCell',{attrs:{"date":item.created_at}})]}},{key:"cell(expires_at)",fn:function(ref){
var item = ref.item;
return [(item.expired = _vm.parseDate(item.expires_at) < new Date())?_c('div',{staticClass:"badge badge-primary d-inline-flex",staticStyle:{"background-color":"#c4081a","font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('teams.view.members.invites.state.expired'))+" ")]):_c('DateCell',{attrs:{"date":item.expires_at}})]}},{key:"cell(role)",fn:function(ref){
var item = ref.item;
return [_c('RoleCell',{attrs:{"role":item.role}})]}},{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [_c('ProfileCell',{attrs:{"user":item.user}})]}},{key:"cell(state)",fn:function(ref){
var item = ref.item;
return [(item.state === 1)?_c('div',[_vm._v(_vm._s(_vm.$t('teams.view.members.invites.state.accepted')))]):(item.state === -1)?_c('div',[_vm._v(_vm._s(_vm.$t('teams.view.members.invites.state.declined')))]):(item.expired)?_c('div',[_vm._v(_vm._s(_vm.$t('teams.view.members.invites.state.expired')))]):_c('div',[_vm._v(_vm._s(_vm.$t('teams.view.members.invites.state.pending')))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn btn-warning btn-sm ml-2 mr-2",on:{"click":function($event){return _vm.removeInvite(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('teams.view.members.invites.remove.button'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }