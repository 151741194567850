<template>
  <router-link :to="{name: 'profile', params: {cftools_id: user.cftools_id}}">
    <template v-if="!user.display_name">
      <img src="#" class="rounded-circle avatar-xs">
      Profile data pending
    </template>
    <template v-else>
      <img :src="user.avatar" alt="profile picture" class="rounded-circle avatar-xs">
      {{ user.display_name }}
    </template>
  </router-link>
</template>

<script>
export default {
  name: "ProfileCell",
  props: ['user']
}
</script>