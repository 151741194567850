<template>
  <div>
    <QueryTable ref="table" :fields="fields" :api-path="`v1/teams/${team_id}/query`">
      <template #cell(created_at)="{ item }">
        <DateCell :date="item.created_at"></DateCell>
      </template>
      <template #cell(role)="{ item }">
        <RoleCell :role="item.role"></RoleCell>
      </template>
      <template #cell(user)="{ item }">
        <ProfileCell :user="item.user"></ProfileCell>
      </template>
      <template #cell(actions)="{ item }">
        <button class="btn btn-warning btn-sm ml-2 mr-2" v-if="item.permissions.remove" @click="removeMember(item.user.cftools_id)">
          {{ $t('teams.view.members.members.remove.button') }}
        </button>
        <button class="btn btn-info btn-sm ml-2 mr-2" v-if="item.permissions.role" @click="modifyMember(item.user.cftools_id)">
          {{ $t('teams.view.members.members.modify.button') }}
        </button>
      </template>
      <div class="float-left">
        <button class="btn btn-primary" v-on:click="inviteMember()" v-if="options.permissions.member.add">
          <i class="fas fa-plus"/>
          {{ $t('teams.view.members.members.invite.button') }}
        </button>
      </div>
    </QueryTable>
    <b-modal id="update-role-modal" size="md" :title="$t('teams.view.members.members.modify.title')" title-class="font-18" hide-footer>
      <template v-if="loadingAvailableRoles">
        <div class="info-component text-center align-middle mt-auto mb-auto">
          <div class="info-component-space">
            <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
          </div>
        </div>
      </template>
      <template v-else-if="availableRolesError">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 class="text-uppercase text-danger">{{ $t('error.server.generic.title') }}</h3>
                  <h5 class="text-muted">{{ $t('error.server.generic.component') }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="availableRoles.length">
        <multiselect
            v-model="newRole"
            label="name"
            track-by="name"
            :options="availableRoles"
            :show-labels="false"
            :allow-empty="true"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="d-inline-flex align-items-center">
              <div class="d-inline-flex badge badge-primary mr-2" :style="{'background': props.option.color}" style="font-size: 1.2rem;">
                <div class="c-dot align-middle mr-1"/>
                <span class="align-middle">
                    {{ props.option.name }}
                  </span>
              </div>
              {{ props.option.uuid }}
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div>
              <div class="option__desc d-inline ml-2">
                <span class="option__title align-middle h4">
                  {{ props.option.name }}
                </span>
                <span class="option__small align-middle ml-2 h6">
                  {{ props.option.uuid }}
                </span>
              </div>
            </div>
          </template>
        </multiselect>
        <div class="row mt-2 justify-content-center">
          <div class="col-lg-6 col-sm-12">
            <button class="btn btn-block btn-primary" v-on:click="updateRole()" :class="{'disabled': memberModifyInProgress}">
              <half-circle-spinner
                  v-if="memberModifyInProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              {{ $t('teams.view.members.members.modify.button') }}
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <h4 class="text-center text-warning">{{ $t('teams.view.members.members.modify.none') }}</h4>
      </template>
    </b-modal>
  </div>
</template>

<script>
import QueryTable from "@/components/widgets/table/QueryTable";
import DateCell from "@/components/widgets/table/components/DateCell";
import ProfileCell from "@/components/widgets/table/components/ProfileCell";
import RoleCell from "@/components/widgets/table/components/RoleCell";
import { HalfCircleSpinner } from "epic-spinners";
import Multiselect from "vue-multiselect";
import { get_acsrf_token } from "@/methods";

export default {
  props: ['team_id', 'options'],
  components: { RoleCell, DateCell, ProfileCell, QueryTable, HalfCircleSpinner, Multiselect },
  data() {
    return {
      fields: [
        {
          key: 'created_at',
          label: this.$t('teams.view.members.members.columns.date'),
          sortable: true
        },
        {
          key: 'role',
          label: this.$t('teams.view.members.members.columns.role'),
          sortable: false
        },
        {
          key: 'user',
          label: this.$t('teams.view.members.members.columns.user'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('teams.view.members.members.columns.actions'),
          sortable: false
        }
      ],
      selectedMember: null,
      newRole: null,
      availableRoles: [],
      loadingAvailableRoles: false,
      availableRolesError: false,
      memberModifyInProgress: false
    }
  },
  methods: {
    async inviteMember() {
      return this.$swal({
        input: 'text',
        text: this.$t('teams.view.members.members.invite.text'),
        title: this.$t('teams.view.members.members.invite.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('teams.view.members.members.invite.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
        showLoaderOnConfirm: true,
        preConfirm: async (cftools_id) => {
          cftools_id = cftools_id.trim();
          const acsrf_token = await get_acsrf_token();
          const payload = {
            acsrf_token: acsrf_token,
            cftools_id: cftools_id
          };
          const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/invites`, {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          });
          if (response.ok) {
            this.$parent.$parent.update();
            return response.json();
          } else if (response.status === 400 || response.status === 404) {
            this.$swal.showValidationMessage(this.$t('teams.view.members.members.add.error'));
          }
        }
      })
    },
    async removeMember(cftools_id) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('teams.view.members.members.remove.text'),
        showCancelButton: true,
        confirmButtonText: this.$t('teams.view.members.members.remove.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if (!result.isConfirmed) {
        return;
      }
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          cftools_id: cftools_id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/member-remove`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if (response.ok) {
          await this.$refs.table.fetchData();
          this.$swal({
            icon: 'success',
            text: this.$t('teams.view.members.members.remove.success.text')
          });
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          }
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    async modifyMember(cftools_id) {
      this.newRole = null;
      this.selectedMember = cftools_id;
      this.loadingAvailableRoles = true;
      this.availableRolesError = false;
      this.$bvModal.show('update-role-modal');
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/member-roles`);
        url.searchParams.append('cftools_id', cftools_id);

        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if (response.ok) {
          let data = await response.json();
          this.availableRoles = data.roles;
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          }
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.availableRolesError = true;
      }
      this.loadingAvailableRoles = false;
    },
    async updateRole() {
      if (!this.newRole) {
        return;
      }
      this.memberModifyInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          cftools_id: this.selectedMember,
          role_id: this.newRole.id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/member-role`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if (response.ok) {
          await this.$refs.table.fetchData();
          this.$bvModal.hide('update-role-modal');

          this.$swal({
            icon: 'success',
            text: this.$t('teams.view.members.members.modify.success.text')
          });
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          }
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.memberModifyInProgress = false;
    }
  }
}
</script>