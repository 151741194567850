<template>
  <ScopedNav :views="views"/>
</template>
<script>
import ScopedNav from "@/components/widgets/nav/ScopedNav";
import Members from "@/components/lazy/teams/view/components/members/Members";
import Invites from "@/components/lazy/teams/view/components/members/Invites";

export default {
  props: ['team_id', 'options'],
  components: {
    ScopedNav,
  },
  data() {
    return {
      views: [{
        title: this.$t('teams.view.members.members.title'),
        component: Members,
        props: {
          team_id: this.team_id,
          options: this.options
        }
      }, ...(this.options.permissions.member.add ? [
        {
          title: this.$t('teams.view.members.invites.title'),
          component: Invites,
          props: {
            team_id: this.team_id,
            options: this.options
          },
          badge: {
            variant: 'secondary',
            text: '0'
          }
        }
      ] : [])]
    }
  },
  methods: {
    async update() {
      const invitesMeta = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/invites-meta`, { credentials: 'include' }).then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${res.status}=${res.statusText} (${res.url})`);
        }
      });
      this.views[1].badge = { variant: invitesMeta.pending_count === 0 ? 'secondary' : null, text: invitesMeta.pending_count.toString() };
    }
  },
  mounted() {
    if(this.options.permissions.member.add) {
      this.update();
    }
  }
};
</script>
