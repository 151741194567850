<template>
  <div class="badge badge-primary d-inline-flex" :style="{'background': role.color}" style="font-size: 1.2rem;">
    <div class="c-dot align-middle mr-1">
      <i class="fad text-black align-middle fa-crown" style="font-size: 0.8rem;" v-if="role.ownership_role"/>
    </div>
    <span class="align-middle"> {{ role.name }} </span>
  </div>
</template>

<script>
export default {
  name: "RoleCell",
  props: ['role']
}
</script>