<template>
  <div class="c-nav-content">
    <div class="c-nav-container">
      <div class="c-page-header">
        <div class="c-page-header-left">
          <div class="c-nav-horizontal">
            <nav class="c-nav">
              <ul class="c-nav-list">
                <li v-for="(view, index) in views" :key="index" class="c-nav-item" :class="{'c-nav-item-active': navIsActive(view), 'active': navIsActive(view)}">
                  <a class="c-nav-link" @click="navigate(view)">
                    {{ view.title }}
                    <small v-if="view.badge" :class="`ml-1 badge badge-${view.badge.variant || 'primary'}`">
                      {{ view.badge.text || view.badge }}
                    </small>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!-- ---- -->
      <div class="c-body">
        <component v-if="view" :is="view.component" v-bind="view.props" @childNavigate="onChildNavigationRequest"></component>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  /*
   views: [{
      title: $t('x.y.z.title'),
      component: () => import('@/components/lazy/x/y/Z'),
      props: {
        a: 1
        b: 'abc'
      },
      badge: '1',
      allowReload: true
   }]
   */
  props: ['views'],
  methods: {
    async navigate(targetView) {
      if (targetView !== this.view || !targetView.allowReload) {
        this.view = targetView;
      } else {
        this.view = null;
        this.$nextTick(() => {
          this.view = targetView;
        })
      }
    },
    navIsActive(view, childView) {
      return (this.view === view || this.view === childView);
    },
    async onChildNavigationRequest(request) {
      await this.navigate(request.component);
    }
  },
  created() {
    if (this.views.length) {
      this.navigate(this.views[0]);
    }
  },
  data() {
    return {
      view: null
    }
  }
};
</script>

<style scoped>
.c-nav-content {
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: auto minmax(1px, 1440px) auto;
  grid-template-areas: ". page-content .";
}

.c-nav-container {
  grid-area: page-content;
}

.c-page-header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "left right";
  margin-bottom: 20px;
  overflow-x: auto !important;
}

.c-page-header-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-area: left;
  justify-content: left;
}

.c-page-header-right {
  grid-area: right;
  align-items: center;
}

.c-nav-content nav {
  display: block;
}

.c-nav-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.c-nav-content a {
  text-decoration: none;
  cursor: pointer;
}

.c-nav-horizontal .c-nav-list {
  display: grid;
  justify-content: start;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 15px;
}

.c-nav-horizontal .c-nav-item {
  display: grid;
  align-items: center;
  margin: 0;
  height: 2rem;
  line-height: 2rem;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.c-nav-item {
  display: flex;
  margin: 0 0 0px;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
}

.c-nav-item-active .c-nav-link, .c-nav-item-active .c-nav-link:hover, .c-nav-item.active .c-nav-link, .c-nav-item.active .c-nav-link:hover {
  color: var(--primary);
}

.c-nav-link {
  transition: color .15s;
  position: relative;
  color: var(--secondary);
  letter-spacing: .05rem;
}

.c-nav-horizontal .c-nav-item-active a:after, .c-nav-horizontal .c-nav-item.active a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary);
}

.c-nav button {
  padding: 0;
  border: 0;
  background: none;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
</style>