<template>
  <div>
    {{ $d(parseDate(date), 'datetime', $store.getters.getDTLocale()) }}
  </div>
</template>

<script>
export default {
  name: "DateCell",
  props: ['date']
}
</script>