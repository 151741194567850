<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="c-table">
          <div class="header">
            <div class="title" @click="toggleFilter">
            <span class="badge badge-primary">
              <h6 class="mb-0 mt-0">
                {{ $t('components.table.filters.title') }}
                <small>
                  {{ $t('components.table.filters.details') }}
                </small>
              </h6>
            </span>
            </div>
            <!-- Primary search term -->
            <div class="spacer"/> <!-- Spacer -->
            <div class="row">
              <div class="col-lg col-sm-12">
                <div class="mt-lg-2">
                  {{ $t('components.table.filters.search_term') }}
                  <span class="badge badge-primary mb-1 st-clear" @click="clearSearchTerm">
                  {{ $t('components.table.filters.clear') }}
                </span>
                </div>
                <b-form-input for="text" v-model="query.search_term" :placeholder="$t('components.table.filters.search_term')"/>
              </div>
            </div>
            <div class="exfilters" v-if="query.expanded">
              <!-- Date range -->
              <div class="spacer"/> <!-- Spacer -->
              <div class="row justify-content-center">
                <div class="col">
                  <div class="row">
                    <div class="col-lg-3 col-sm-12 text-lg-center">
                      <b-form-checkbox switch v-model="query.date.start.enabled" class="mt-lg-2">
                        {{ $t('components.table.filters.date.start') }}
                      </b-form-checkbox>
                    </div>
                    <div class="col-lg col-sm-12">
                      <div class="sm-spacer"/> <!-- Spacer -->
                      <b-form-input v-model="query.date.start.value" type="date"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="sm-spacer"/> <!-- Spacer -->
                  <div class="row">
                    <div class="col-lg-3 col-sm-12 text-lg-center">
                      <b-form-checkbox switch v-model="query.date.end.enabled" class="mt-lg-2">
                        {{ $t('components.table.filters.date.end') }}
                      </b-form-checkbox>
                    </div>
                    <div class="col-lg col-sm-12">
                      <div class="sm-spacer"/> <!-- Spacer -->
                      <b-form-input v-model="query.date.end.value" type="date"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Button -->
            <div class="spacer"/> <!-- Spacer -->
            <div class="row justify-content-center">
              <div class="col-lg-4 col-sm-12">
                <button class="btn btn-primary btn-block" v-on:click="fetchData">
                  <i class="fad fa-search"></i>
                  {{ $t('components.table.filters.search') }}
                </button>
              </div>
            </div>
            <div class="float-right" v-if="query.delta > 0">
              <small>Query duration {{ query.delta }}ms</small>
            </div>
          </div>
          <div class="mt-2">
            <div v-if="loading" class="progress-overlay">
              <div class="text-center align-middle mt-auto mb-auto">
                <div class="info-component-space">
                  <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
                </div>
              </div>
            </div>
            <b-table class="table-responsive-sm" :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                     show-empty>
              <template #empty="scope">
                <h4>{{ $t('components.table.filters.empty') }}</h4>
              </template>
              <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
              </template>
            </b-table>
          </div>
          <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";

export default {
  props: ['fields', 'apiPath'],
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      loading: false,
      items: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: null,
      sortDesc: false,
      query: {
        delta: 0.0,
        expanded: false,
        search_term: null,
        date: {
          start: {
            enabled: false,
            value: null
          },
          end: {
            enabled: false,
            value: null
          }
        }
      }
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const url = new URL(process.env.VUE_APP_ROOT_API + this.apiPath);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      if (this.sortBy) {
        url.searchParams.append('sort', JSON.stringify({
          key: this.sortBy,
          dir: this.sortDesc ? -1 : 1
        }));
      }

      url.searchParams.append('query', JSON.stringify({
        search_term: this.query.search_term,
        date: {
          ...(this.query.date.start.enabled && this.query.date.start && {
            start: this.query.date.start.value
          }),
          ...(this.query.date.end.enabled && this.query.date.start && {
            end: new Date(Date.parse(this.query.date.end.value) + 1000 * 60 * 60 * 24).toISOString()
          })
        }
      }));
      const time = Date.now();
      this.items = await fetch(url, { credentials: 'include' })
          .then(async res => {
            if (res.status === 200) {
              const data = await res.json()
              this.totalItems = parseInt(data.meta.total_items)
              return data.entries
            } else if (res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            } else {
              this.$toast.error('Failed to contact query service');
            }
            return []
          }).catch(console.error);
      this.query.delta = Date.now() - time;
      this.loading = false
    },
    toggleFilter() {
      this.query.expanded = !this.query.expanded
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      await this.fetchData();
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    sortBy(value) {
      if (value) {
        this.fetchData()
      }
    },
    sortDesc() {
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="scss">
.c-table {
  .header {
    border-radius: 5px;
    border: 2px solid #7f8387;
    padding: 1rem;
  }

  .title {
    position: absolute;
    margin-top: -27px;
    margin-left: 1rem;
    z-index: 1;
    cursor: pointer;
  }

  .spacer {
    margin-top: .5rem;
  }

  .exfilters {
    transition: all 1s;
  }

  .progress-overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: -1rem;
    margin-right: auto;
    margin-top: 3rem;
  }
}
</style>